.App {
  overflow: hidden;
}

.webchat-container table{
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}
.webchat-container th, td{
  text-align: left;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 0.5px solid #515050;
  font-weight: 400;
  font-size: small;
}

/* Notification Style */

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f0f0f0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1a1a1c;
  padding: 15px;
  border-radius: 5px;
  z-index: 1002;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.notification.success {
  background-color: #eefde3;
}

.notification.error {
  background-color: #fde8e8;
}

.notification .close-icon {
  cursor: pointer;
}

/* Error Message text */
.error-message {
  font-size: 14px;
  color: red;
  padding-bottom: 8px;
}

/* Page not found style */
.not-found-container {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    color: #696969;
    font-size: 24px;
}

/* Button Style */
.btn-style-reset {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #696969;
  padding-top: 16px;
  font-size: 16px;
}

.btn-style-reset:hover {
  color: #000;
  text-decoration: underline;
  background-color: white;
}

.btn-alert-close {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #696969;
  height: 20px;
  width: 20px;
}

/* Loading Style */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Toast Style */
@keyframes toast-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.toast {
  min-width: 15rem;
  padding: 12px;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  animation: toast-fade-in 0.5s ease-out;
}


.toast.toast-success {
  background-color: #EEFDE3;
  color: #1A1A1C;
}

.toast.toast-error {
  background-color: #FDE3E5;
  color: #1A1A1C;
}

.toast.toast-warning {
  background-color: #ffcc00;
  color: #1A1A1C;
}

.toast.toast-info {
  background-color: #646cff;
  color: #fff;
}

.toast .btn-alert-close {
  background: none;
  border: none;
  color: #7B7B86;
  cursor: pointer;
  padding: 0;
  height: 24px;
}

.toast .btn-alert-close:focus {
  color: inherit;
  border: none;
}

.toast .toast-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toast .toast-title {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  width: 100%;
}

.toast .toast-message {
  font-size: 12px;
  text-align: left;
  margin: 0;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.toast .toast-icon-success {
  color: #5ED40D;
  height: 24px;
  width: 24px;
}

.toast .toast-icon-error {
  color: #ED1C24;
  height: 24px;
  width: 24px;
}

/* Others */
.cursor-pointer {
  cursor: pointer;
}

/* Session Expired Modal */
.session-expired__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.session-expired__modal {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
.session-expired__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.session-expired__title {
    color: #1a1a1c;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    width: 100%;
    margin: 0;
}
.session-expired__separator-line {
    border-bottom: 0.5px solid #cbcbcf;
    margin-top: 10px;
    margin-bottom: 10px;
}
.session-expired__close {
    background-color: white;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.session-expired__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #1a1a1c;
    max-height: 500px;
    overflow: auto;
}
.session-expired__footer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;
}
.btn-primary {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #ed1c24;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}