.homepage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.banner {
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator-line {
  border: none;
  border-top: 4px solid #ED1C24;
  margin: 4px 0; 
}

.left-side {
  display: flex;
  align-items: center;
}

.right-side {
  display: flex;
  align-items: center;
}

.logout-button {
  height: 48px;
  width: 91px;
  background-color: #ED1C24;
  color: #fff;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid red;
  border-radius: 60px;
  padding: 12px, 24px, 12px, 24px;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: red;
  color: white;
}

.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 80px;
}

.box {
  width: 424px;
  height: 240px;
  padding: 16px;
  border-radius: 12px;
  background-color: #ED1C24;
  color: white;
  position: relative; 
  display: flex;
  justify-content: center;
  align-items: center; 
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s;
  overflow: hidden;
}

.box:hover {
  transform: scale(1.1);
}

.box-title {
  z-index: 1;
  text-align: center; 
}

.desktop-icon {
  position: absolute;
  bottom: 0;
  left: 0;
}
