.eva-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 8px;
}

.eva-dashboard .overlap-group {
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 20px;

}

.eva-dashboard .ellipse {
  height: 40px;
  border-radius: 50%;
}

.text-wrapper {
  font-size: 24px;
  font-weight: 500;
  color: white;
}