.moveable {
  cursor: move;
}

.eva-btn {
  background-color: #c6c6c6;
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  left: 67%;
  top: 1.5%;
  cursor: pointer;
} 

.eva-btn:disabled {
  cursor: not-allowed;
}

.eva-btn-image {
  border-radius: 50%;
  position: absolute;
  left: 67%;
  top: 1.5%;
}

.eva-btn-text {
  color: #ffffff;
  font-family: "Segoe UI-Bold", Helvetica;
  font-weight: 700;
  position: absolute;
}

.sliding-div {
  position: absolute;
  top: 0;
  right: -100%;
  width: 45%;
  height: 100%;
  background-color: transparent;
  transition: right 0.3s ease-in-out;
}

.sliding-div.visible {
  right: 0;
}