
.webchat__css-custom-properties {
  height: 100vh;
}

.MVDDesktop {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.webchat-container {
  height: 92vh !important;
}

.webchat-container .webchat__basic-transcript {
  padding-bottom: 10px;
}

.webchat-container .webchat__send-box {
  padding-right: 16px;
}

.webchat-container .ac-pushButton {
  appearance: none;
  border-style: none !important;
  color: rgb(0, 99, 177);
  font-weight: 500;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #444444 !important;
}

.webchat-container .ac-pushButton:hover {
  background-color: #0063B1 !important;
  color: white !important;
}

.webchat-container .ac-pushButton[aria-pressed="true"]{
  background-color: #0063B1 !important;
  color: white !important;
}

.webchat-container .ac-actionSet{
  flex-wrap: wrap;
  row-gap: 8px;
}

.webchat-container .ac-input {
  padding: 8px;
  padding-left: 12px;
  border-radius: 4px;
  background-color: #444444;
  box-shadow: none;
  border: 1px solid #212124;
  color: white;
  opacity: 1;
}

.webchat-container .ac-input::placeholder {
  color: #E6E6E8;
}

.webchat-container .ac-input {
  padding: 8px;
  padding-left: 12px;
  border-radius: 4px;
  background-color: #444444;
  box-shadow: none;
  border: 0.5px solid #363636;
  color: white;
  opacity: 1;
}

.webchat-container .ac-input::placeholder {
  color: #E6E6E8;
}

.webchat-container .ac-textBlock {
  font-size: 15px !important;
  color: white !important;
  line-height: normal !important;
}

.webchat-container a{
  color: white !important;
  text-decoration: underline;
}

.webchat-container a > img{
  display: none;
}